import { BodyText3, Button, Heading3 } from '@lumoslabs/lumosity-storybook'
import Link from 'next/link'
import styled from 'styled-components'

import QueryLoader from '~/components/QueryLoader'
import SkeletonLoader from '~/components/ui/SkeletonLoader'
import WorkoutRing from '~/components/workout/WorkoutRing'
import { EventClick, EventScreenName } from '~/events/eventTypes'
import useTrackClick from '~/events/trackers/useTrackClick'
import useLocalDate from '~/hooks/useLocalDate'
import useTodaysWorkout from '~/hooks/useTodaysWorkout'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import { nonTokenColors } from '~/styles/color'
import { WorkoutMetaNotNull } from '~/utils/workoutUtils'

export interface WorkoutCardProps {
  workoutMeta: WorkoutMetaNotNull
}

// don't use this component directly, use WorkoutCardWrapper instead
// exported for testing purposes
export const WorkoutCard: React.FC<WorkoutCardProps> = ({ workoutMeta }) => {
  const t = useTranslationForNamespace('home')

  const { trackCta } = useTrackClick()
  const { dateFormatted } = useLocalDate()

  // Prevent the remaining game count going below 0 when the sub status goes from Premium to Free
  const { remainingGameCount, nextUnplayedGame, isWorkoutComplete, isWorkoutStarted } = workoutMeta
  const nextGameSlug = nextUnplayedGame?.game?.slug
  const gameUrl = nextGameSlug ? `/workout` : '/'

  const handleClick = (text: string) => {
    trackCta({
      text: t(`common:buttons.${text}`),
      type: EventClick.types.Button,
      destination: EventScreenName.Pregame,
      click_name: EventClick.names.HomeWorkoutPlay,
    })
  }

  const WorkoutCardContent = () => {
    if (isWorkoutComplete) {
      const headingText = t('workoutCard.headings.today.workoutComplete')
      const subheadingText = t('workoutCard.subheadings.today.workoutComplete')

      return (
        <Content>
          <InfoSection>
            <Heading3>{headingText}</Heading3>
            <BodyText3>{subheadingText}</BodyText3>
          </InfoSection>
        </Content>
      )
    } else {
      const headingText = t('workoutCard.headings.today.workoutIncomplete')

      const CTA = !isWorkoutStarted ? 'begin' : 'continue'
      // Don't include remains if workout hasn't been started
      const gamesRemainingText = !isWorkoutStarted
        ? t('workoutCard.subheadings.today.gamesRemainingInitial', { count: remainingGameCount })
        : t('workoutCard.subheadings.today.gamesRemaining', { count: remainingGameCount })

      return (
        <Content>
          <InfoSection>
            <Heading3>{headingText}</Heading3>
            <BodyText3>
              {dateFormatted}&nbsp;&nbsp;|&nbsp;&nbsp;{gamesRemainingText}
            </BodyText3>
          </InfoSection>
          <WorkoutLongButton href={gameUrl} passHref={true} prefetch={false} onClick={() => handleClick(CTA)}>
            <Button kind='primary' wide={true}>
              {t(`common:buttons.${CTA}`)}
            </Button>
          </WorkoutLongButton>
        </Content>
      )
    }
  }

  return (
    <WorkoutCardContainer $isCompleted={isWorkoutComplete}>
      <WorkoutRingContainer>
        <WorkoutRing workoutMeta={workoutMeta} />
      </WorkoutRingContainer>
      <WorkoutCardContent />
    </WorkoutCardContainer>
  )
}

const WorkoutCardWrapper = () => {
  const { workoutMeta, loading, error } = useTodaysWorkout()

  return (
    <QueryLoader data={workoutMeta} loading={loading} error={error} LoadingComponent={<Skeleton />}>
      {workoutMeta && <WorkoutCard workoutMeta={workoutMeta} />}
    </QueryLoader>
  )
}
export default WorkoutCardWrapper

const Skeleton = styled(SkeletonLoader)`
  width: min(592px, 100%);
  height: 240px;
`

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
`

const WorkoutLongButton = styled(Link)`
  max-height: 52px;
  display: inline-flex;
  align-items: centre;
  width: min(100%, 240px);
`

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  color: ${({ theme }) => theme.colorTokens.text['text-default']};
  padding: 0px 32px;
`

const WorkoutCardContainer = styled.div<{ $isCompleted: boolean }>`
  overflow: hidden;
  position: relative;
  max-width: min(100%, 592px);
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  padding: 32px;
  background: ${({ theme }) => theme.colorTokens.surface['surface-default']};
  box-shadow: ${(props) =>
    props.$isCompleted
      ? `0px 0px 0px 1px ${nonTokenColors.neutralCool90}`
      : `2px 2px 8px 2px ${nonTokenColors.neutralCool90}`};
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    flex-direction: column;
    gap: 8px;
    text-align: center;
  }
`

const WorkoutRingContainer = styled.div`
  width: 148px;
  height: 148px;
  display: flex;
  justify-content: center;
`
