import React, { useContext } from 'react'

import styled, { css } from 'styled-components'

import { AuthContext } from '~/context/AuthContext'
import LumosityCircleIcon from '~/images/icons/Brand/LumosityCircleColor.svg'
import WorkoutComplete from '~/images/Workout/WorkoutCompleteYellow.svg'
import WorkoutSegmentSvg from '~/images/Workout/WorkoutSegment.svg'
import { nonTokenColors } from '~/styles/color'
import { WorkoutMetaNotNull } from '~/utils/workoutUtils'

interface WorkoutRingProps {
  workoutMeta: WorkoutMetaNotNull
  slug?: string // if slug is passed, treat that as active ring, i.e. used in game screens, mainly used in postGame screen
  checkmarkDelay?: number
}

type WorkoutSegmentStatus = 'Inactive' | 'Active' | 'Complete' | 'Locked'

export const WorkoutRingStatus: Record<
  WorkoutSegmentStatus,
  { code: WorkoutSegmentStatus; color: string; animation: string }
> = {
  Inactive: { code: 'Inactive', color: nonTokenColors.neutralWarn95, animation: 'fadeIn 1s forwards' },
  Active: { code: 'Active', color: nonTokenColors.neutralWarn95, animation: 'fadeInOut 1.2s infinite ease-in-out' }, // blinking effect
  Complete: { code: 'Complete', color: nonTokenColors.accentSecondary60, animation: 'fadeIn 1s forwards' },
  Locked: { code: 'Locked', color: nonTokenColors.neutralWarm85, animation: 'fadeIn 1s forwards' },
} as const

const WorkoutRing: React.FC<WorkoutRingProps> = ({ workoutMeta, slug }) => {
  const { hasPremium } = useContext(AuthContext)

  const { allPremiumSlots, isWorkoutComplete } = workoutMeta

  let lastUnplayedGame = false // helps in determining the active ring
  const mappedSlots = allPremiumSlots.map((slot: any, index: number) => {
    const status = (() => {
      // if the slug matches, consider that step as active
      if (slug === slot.game.slug) {
        lastUnplayedGame = true // only 1 active ring at a time
        // if the game is already played, consider it as complete
        if (slot.lastGamePlay) return WorkoutRingStatus.Complete.code
        return WorkoutRingStatus.Active.code
      }

      // 3rd & 4th slot is locked for free users
      if (!hasPremium && (index === 2 || index === 3)) {
        return WorkoutRingStatus.Locked.code
      }

      // if the game is already played, consider it as complete
      if (slot.lastGamePlay) return WorkoutRingStatus.Complete.code

      // if the game is not played yet, consider it as active
      if (!lastUnplayedGame) {
        lastUnplayedGame = true // only 1 active ring at a time
        return WorkoutRingStatus.Active.code
      }

      return WorkoutRingStatus.Inactive.code
    })()

    return {
      ...slot,
      ringStatus: status,
      ringSvg: <WorkoutSegment status={status} slotNumber={index + 1} />,
      isPlayed: !!slot.lastGamePlay,
    }
  })

  const Rings = mappedSlots.map((slot) => {
    return <React.Fragment key={slot.game.slug}>{slot.ringSvg}</React.Fragment>
  })

  const isWorkoutFinish = (() => {
    if (slug) return false // don't show workout completed if a slug is passed
    return isWorkoutComplete
  })()

  return (
    <WorkoutSegmentContainer>
      <AnimationContainer>
        {isWorkoutFinish ? <CenterIconWorkoutComplete /> : <CenterIconLumosityIcon />}
        {isWorkoutFinish ? null : Rings}
      </AnimationContainer>
    </WorkoutSegmentContainer>
  )
}

const AnimationContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-content: center;
`

const WorkoutSegmentContainer = styled.div`
  aspect-ratio: 1/1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`

export default WorkoutRing

const WorkoutSegment = ({ status, slotNumber }: { status: keyof typeof WorkoutRingStatus; slotNumber: number }) => {
  const ringConfig = WorkoutRingStatus[status] || {}
  return (
    <WorkoutSegmentSvg
      title='workout-ring'
      style={{
        opacity: 0,
        color: ringConfig.color || nonTokenColors.neutralWarn95,
        // the svg represent 3rd slot visually because it needs to be 0° square (example 10° rotates square takes more space in dom, 0° makes it natural),
        // thus adjust it so that it starts from 1st slot.
        transform: `rotate(${(slotNumber + 2) * 72}deg)`,
        animation: ringConfig.animation || '',
        animationDelay: `${slotNumber * 0.1}s`,
        transformOrigin: '50% 50%',
        width: '100%',
        height: '100%',
        position: 'absolute',
      }}
    />
  )
}

const CenterIconCss = css`
  width: 100%;
  height: 100%;
  padding: 20%; // 20% padding is taken up by rings
  position: absolute;
  animation: fadeIn 1s forwards;
`

const CenterIconWorkoutComplete = styled(WorkoutComplete).attrs({ title: 'workout-complete' })`
  ${CenterIconCss}
`
const CenterIconLumosityIcon = styled(LumosityCircleIcon).attrs({ title: 'lumosity-icon' })`
  ${CenterIconCss}
`
